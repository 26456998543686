<template>
  <div>
    <div class="toptime">
      <navigation :msg="msg"></navigation>
      <div class="timeclasses">
        <div @click="show = true" class="thetime">
          <div>{{ datamsg }}</div>
          <van-icon name="arrow-down" />
        </div>
      </div>
    </div>
    <div class="nomsg"></div>

    <div v-for="(item, index) in allmsg" v-bind:key="index" class="maslist">
      <div class="topmsg">
        <div>{{item.company_name}}</div>
        <div class="timeclass">
          <img id="timeimg" src="../../assets/time.png" />
          <div>{{item.add_time}}</div>
        </div>
      </div>
      <div class="moneyclass">
        <div>+{{item.channel_money}}元</div>
        <div>收益</div>
      </div>
  
    </div>
    <loading @loaddom="loaddom" ref="loades"></loading>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '40%' }"
    >
      <van-datetime-picker
        v-model="currentDate"
        type="year-month"
        @confirm="confirm"
        @cancel="cancel"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
import loading from "../../components/loading/index";
export default {
  components: {
    navigation,
    loading,
  },
  data() {
    return {
      msg: "我的收益",
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      show: false,
      datamsg:"",
      allmsg: "", //所有收益记录
      year: "", //年
      month: "", //月
      pageno: 0,
      selecttime:""
    };
  },
  created(){
    this.getNowDate();
  },
  mounted() {},
  methods: {
    //获取当前年月
    getNowDate() {
      this.year = new Date().getFullYear();
      this.month = (new Date().getMonth()+"").length==1?'0'+(new Date().getMonth()):new Date().getMonth();
      // if(this.month==0){
      //   this.month = 12;
      //   this.year = this.year-1;
      // }
       this.datamsg = this.year+"年"+this.month+"月";
      this.selecttime = this.year + "-" + this.month;
    },
    //获取搜索年月
    getSelectDate() {
      return this.year + "-" + this.month;
    },

    loaddom() {
      this.$refs.loades
        .loadhttpes("/firm/v1/Channel/my_profit1", {
          reqType: "userinfo",
          pageno: ++this.pageno,
          limit: "15",
          date: this.selecttime
        })
        .then((res) => {
           
          this.allmsg = res.arraymsg;
        });
    },

    //获取搜索月份收益数据
    getTimeMsg() {
      this.$refs.loades
        .loadhttpes("/firm/v1/Channel/my_profit1", {
          reqType: "userinfo",
          pageno: "1",
          limit: "15",
          date: this.selecttime,
        })
        .then((res) => {
           
          this.allmsg = res.arraymsg;
        });
    },

    confirm(msg) {
      console.log(msg);
      this.$refs.loades.empty();
      let da = new Date(msg);
      if(da.getMonth()==new Date().getMonth()&&da.getFullYear()==new Date().getFullYear()){
        this.$toast("当月收益正在统计中");
        return;
      }
      this.datamsg =
        da.getFullYear() + "年" + parseInt(da.getMonth()+1) + "月";
      this.show = false;
      this.year = da.getFullYear();
      this.month = (da.getMonth()+1+"").length==1?"0"+(da.getMonth()+1):da.getMonth()+1;
      this.selecttime = this.year+"-"+this.month;
      this.getTimeMsg();
    },
    cancel() {
      this.show = false;
    },
  },
};
</script>
<style scoped>
.nomsg {
  height: 1.8rem;
}
.toptime {
  position: fixed;
  width: 100%;
  top: 0;
}
.codeclass {
  font-size: 0.24rem;
}
.moneyclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.14rem 0;
}
.moneyclass > div:first-child {
  font-size: 0.34rem;
}
.moneyclass > div:nth-child(2) {
  font-size: 0.28rem;
}
.topmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.3rem;
}
.maslist {
  margin: 0.21rem 0.31rem;
  border-radius: 0.2rem;
  background-color: #ffffff;
  padding: 0.38rem 0.3rem;
}
.thetime {
  display: flex;
  align-items: center;
}
.thetime > div:first-child {
  margin-right: 0.1rem;
}
.timeclass {
  display: flex;
  align-items: center;
  font-size: 0.24rem;
}
#timeimg {
  width: 0.21rem;
  height: 0.21rem;
  margin-right: 0.1rem;
  margin-left: 0.3rem;
}
.timeclasses {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.13rem 0;
  font-size: 0.32rem;
}
.timeclasses > div {
  padding: 0.11rem 0.36rem;
  background: #e3e3e3;
  border-radius: 1rem;
}
</style>