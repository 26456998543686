<template>
  <div>
    <div v-show="isloading == true" class="loadclass">
      <van-loading type="spinner">加载中...</van-loading>
    </div>
    <van-empty
      v-show="ishavemsg == false"
      :image="imges"
      description="- 空空如也，一条数据也没有 -"
    />

    <div
     :style="stylemsg" 
      v-show="withoutmsg == true"
      @click="clickload"
      class="allmore"
    >
      <div v-show="open != true">
        <span class="iconclass">
          <van-icon name="arrow-down" />
        </span>
        <span>加载更多</span>
      </div>
      <div v-show="open == true">
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    stylemsg: String,
  },
  data() {
    return {
      isloading: false,
      ishavemsg: true,
      thissettime: "",
      withoutmsg: false,
      open: false,
      url: "",
      imges: require("../../assets/nomsg.png"),
      arraymsg: [], //存储数据
      count: 0, //数据数量
    };
  },
  mounted() {
    this.$emit("loaddom", this.url);
  },
  methods: {
    //清空数据
    empty() {
      this.arraymsg = [];
      this.count = 0;
    },
    clickload() {
      this.open = true;
      this.$emit("loaddom", this.url);
    },
    /**
     * 请求
     */
    loadhttpes(url, objes) {
      this.url = url;
      return new Promise((resolve, reject) => {
        this.thissettime = setTimeout(() => {
          this.isloading = true;
        }, 600);
        this.ishavemsg = true;
        clearTimeout(aa);
        var aa = setTimeout(() => {
          this.getmsg(url, objes, resolve, reject);
        }, 150);
      });
    },
    //获取信息
    getmsg(url, objes, resolve, reject) {
      this.$http
        .post(url, objes)
        .then((res) => {
          //防止tab页多次点击
          Object.keys(objes).forEach((e) => {
            if (e.indexOf("page") != -1) {
              if (objes[e] == 1 || objes[e] < 1) {
                this.arraymsg = [];
                this.count = 0;
              }
            }
          });
          this.open = false;
          clearTimeout(this.thissettime);
          var obj = JSON.parse(res.data);
          Object.keys(obj.data).forEach((keys) => {
            if (
              Object.prototype.toString.call(obj.data[keys]) ==
              "[object Object]"
            ) {
              
              this.count = obj.data[keys].total;
              obj.data[keys] = obj.data[keys].data;
            } else {
            }
            if (
              Object.prototype.toString.call(obj.data[keys]) == "[object Array]"
            ) {
              if (obj.data[keys].length < 1) {
                this.isloading = false;
                this.ishavemsg = false;
                this.withoutmsg = false;
                resolve({
                  arraymsg: this.arraymsg,
                  res: JSON.parse(res.data),
                });
              } else {
                for (let i = 0; i < obj.data[keys].length; i++) {
                  this.arraymsg.push(obj.data[keys][i]);
                }
                 
                this.isloading = false;
                this.ishavemsg = true;
                this.withoutmsg = true;
                Object.keys(objes).forEach((key) => {
                  if (key.indexOf("page") != -1) {
                    if (this.count == 0) {
                       
                      if (obj.data.count) {
                        this.count = obj.data.count;
                      } else {
                        if (obj.data.total) {
                          this.count = obj.data.total;
                        } else {
                          this.count = obj.data.count;
                        }
                      }
                       
                    }
                     
                    if (
                      objes[key] * 15 > this.count ||
                      objes[key] * 15 == this.count
                    ) {
                       
                      this.withoutmsg = false;
                    }
                  }
                });

                resolve({
                  arraymsg: this.arraymsg,
                  res: JSON.parse(res.data),
                });
              }
            } else {
              this.isloading = false;
            }
          });
        })
        .catch(() => {
          reject();
        });
    },
  },
};
</script>
<style scoped>
.van-empty >>> .van-empty__image {
  width: 2rem;
  height: 2.7rem;
}
.loadclass {
  text-align: center;
  padding-top: 2rem;
}
.allmore {
  width: 80%;
  background-color: #ffffff;
  margin: 0.3rem 0 0.1rem 50%;
  transform: translate(-50%);
  text-align: center;
  position: relative;
  padding: 0.3rem 0.2rem;
  margin-bottom: 1rem;
  font-size: 0.35rem;
  border-radius: 2rem;
  color: #202124;
}
.iconclass {
  position: absolute;
  left: 0.45rem;
  top: 50%;
  transform: translateY(-50%);
}
</style>